import Vue from 'vue';
import App from './App.vue';
import router from './router';
import vuetify from './plugins/vuetify';
import globalVariables from './global';
import credentials from '../.google-creds.json';
import * as VueGoogleMaps from 'vue2-google-maps'
import '@/assets/styles.scss';
import './filters';

Vue.config.productionTip = false;

Vue.use(VueGoogleMaps, {
	load: {
		key: credentials.api_key,
	},
	installComponents: true,
})

new Vue({
	router,
	vuetify,
	data: {
		...globalVariables
	},
	render: (h) => h(App),
}).$mount('#app');
