import Vue from 'vue';
import VueRouter, {RouteConfig} from 'vue-router';
import Home from '../views/Home.vue';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
	{path: '/', name: 'Home', component: Home,},
];

const router = new VueRouter({
	mode: 'history',
	base: location.pathname,
	routes,
});

export default router;
