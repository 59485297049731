import Vue from 'vue';
import moment from 'moment';

Vue.filter('dateFormat', (date: string, format = 'YYYY-MM-DD') => {
	return moment(date).format(format);
});

Vue.filter('substanceIcon', (name = 'other') => {
	const icons = ['biological', 'drugs', 'electronic', 'ied', 'liquid', 'money', 'other', 'powder', 'suboxone', 'threat-letter', 'weapon'];
	for (let i = 0; i < icons.length; i++) {
		const icon = icons[i];
		if (name.toLowerCase().replaceAll(/ /gim, '-').indexOf(icon) !== -1) {
			return 'images/markers/' + icon + '.png';
		}
	}
	console.log('missing icon: ', name);
	return 'images/markers/other.png';
});


