import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
	theme: {
		themes: {
			light: {
				primary: '#5ECAF4',
				background: '#eee',

				threat1: '#FF0000',
				threat2: '#00FF00',
				threat3: '#0000FF',
				threat4: '#FF6600',
				threat5: '#FF00FF',
				threat6: '#9900ff',
				threat7: '#00FFFF',
				threat8: '#FFFF00',
				threat9: '#000099',
			},
		},
	},
});
