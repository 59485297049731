<template>
	<v-app class="overflow-hidden">
		<v-main>
			<router-view />
		</v-main>
	</v-app>
</template>

<script>
export default {
	name: 'App',
};
</script>
