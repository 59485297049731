<template>
	<v-dialog v-model="visible" v-bind="$attrs" :persistent="disabled" v-on="$listeners">
		<v-card :color="color">
			<v-card-title class="d-flex justify-space-between flex-nowrap">
				<span class="headline text-truncate">
					<slot name="title">
						<v-icon v-if="icon" left v-text="icon"></v-icon>
						<span v-text="title"></span>
					</slot>
				</span>

				<v-btn icon :disabled="disabled" @click="visible = false">
					<v-icon>mdi-close</v-icon>
				</v-btn>
			</v-card-title>
			<v-card-text :class="bodyColor">
				<slot name="body">
					<span v-html="body"></span>
				</slot>
			</v-card-text>
			<slot name="footer">
				<v-divider></v-divider>
				<v-card-actions>
					<slot name="prepend_actions"></slot>
					<v-spacer></v-spacer>
					<slot name="actions">
						<v-btn v-for="(btn, btnIdx) in buttons" :key="btnIdx" :disabled="disabled" v-bind="btn.attrs" large v-on="btn.events">
							<span v-text="btn.text"></span>
						</v-btn>
					</slot>
				</v-card-actions>
			</slot>
		</v-card>
	</v-dialog>
</template>

<script>
export default {
	name: 'Modal',

	props: {
		value: {
			type: Boolean,
			default: false,
		},
		disabled: {
			type: Boolean,
			default: false,
		},
		color: {
			type: String,
			default: null,
		},
		title: {
			type: String,
			default: null,
		},
		icon: {
			type: String,
			default: null,
		},
		body: {
			type: String,
			default: null,
		},
		bodyColor: {
			type: String,
			default: null,
		},
		buttons: {
			type: Array,
			default: () => ([]),
		},
	},

	computed: {
		visible: {
			get() {
				return this.value;
			},
			set(value) {
				this.$emit('input', value);
			},
		},
	},
};
</script>
