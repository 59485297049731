<template>
	<v-sheet class="fill-height">

		<!-- LOADING -->
		<v-sheet v-if="loading" style="flex: 1" class="d-flex align-center justify-center fill-height">
			<div style="width: 10rem">
				<v-progress-linear color="primary" indeterminate></v-progress-linear>
			</div>
		</v-sheet>

		<template v-else>

			<!-- MAP -->
			<MapView
				ref="map"
				class="fill-height"
				:center="center"
				:zoom="zoom"
				:markers="markers"
				:options="{
					fullscreenControl: false,
					streetViewControl: false,
					mapTypeControl: false,
				}"
				@bounds="handleMapMoved"
				@markerClick="handleMarkerClick"
			/>

			<!-- FILTERS -->
			<v-navigation-drawer width="350" class="mt-4 ml-4 elevation-3" color="rgba(255, 255, 255, 0.9)" style="border-radius: 0.75rem" height="auto" absolute left permanent>
				<template #prepend>
					<div class="px-4 pt-4">
						<h2>Filters</h2>
						<v-divider class="my-4"></v-divider>
					</div>
				</template>
				<template #default>
					<div class="px-4">
						<v-select
							class="mt-2"
							v-model="filters.range"
							:items="ranges"
							label="Range"
							return-object
							clearable
							outlined
						/>
						<v-select
							v-model="filters.container"
							:items="containers"
							label="Container"
							clearable
							outlined
						/>
						<v-select
							v-model="filters.substance"
							:items="substances"
							label="Substance"
							clearable
							outlined
						>
							<template #item="{ item }">
								<div class="d-flex align-center">
									<img class="mr-4" width="32" height="32" :src="$root.getSubstanceIcon(item)" />
									<span style="flex: 1" v-text="item"></span>
								</div>
							</template>
							<template #selection="{ item }">
								<div class="d-flex align-center">
									<img class="mr-4" width="32" height="32" :src="$root.getSubstanceIcon(item)" />
									<span style="flex: 1" v-text="item"></span>
								</div>
							</template>
						</v-select>

						<v-select
							v-model="filters.target_type"
							:items="targetTypes"
							label="Target type"
							clearable
							outlined
						/>
						<v-select
							v-model="filters.primary_responder"
							:items="responders"
							label="Responder"
							clearable
							outlined
						/>
						<v-row class="mb-2">
							<v-col cols="6">
								<v-switch
									class="mt-0 ml-3 pt-0"
									v-model="filters.drug"
									label="Drug"
									:true-value="true"
									inset
								/>
							</v-col>
							<v-col cols="6">
								<v-switch
									class="mt-0 ml-3 pt-0"
									v-model="filters.cbrn"
									label="CBRN"
									:true-value="true"
									inset
								/>
							</v-col>
						</v-row>
					</div>
				</template>
			</v-navigation-drawer>

			<!-- NEWS -->
			<v-navigation-drawer width="450" class="mt-4 mr-4 elevation-3" color="rgba(255, 255, 255, 0.9)" style="border-radius: 0.75rem" height="50vh" absolute right permanent>
				<template #prepend>
					<div class="px-4 pt-4">
						<h2>News feed</h2>
						<v-divider class="my-4"></v-divider>
					</div>
				</template>
				<template #default>
					<div class="px-4">
						<v-alert v-if="_news.length === 0" type="info" outlined prominent>
							No news found
						</v-alert>

						<v-list v-else class="pt-0 pb-4" two-line>
							<v-list-item :key="item.source" v-for="item in _news" :href="item.source" target="_blank">
								<v-list-item-avatar v-if="item.source_thumbnail" tile size="48">
									<v-img :src="item.source_thumbnail"></v-img>
								</v-list-item-avatar>
								<v-list-item-content>
									<v-list-item-title v-if="item.source_title" v-text="item.source_title"></v-list-item-title>
									<v-list-item-subtitle v-if="item.source_description" v-text="item.source_description"></v-list-item-subtitle>
									<v-list-item-subtitle v-text="item.source"></v-list-item-subtitle>
								</v-list-item-content>
								<v-list-item-action>
									<v-list-item-action-text v-text="item.formattedDate"></v-list-item-action-text>
								</v-list-item-action>
							</v-list-item>
						</v-list>
					</div>
				</template>
			</v-navigation-drawer>

			<!-- MARKER DIALOG -->
			<Modal v-model="markerDialog.visible" body-color="background" :title="(markerDialog.marker.data[markerDialog.index] || {}).country" max-width="500" scrollable>
				<template #body>
					<div class="pt-4"></div>
					<v-card v-if="markerDialog.marker.data[markerDialog.index]">
						<v-list color="transparent" dense>
							<v-list-item>
								<v-list-item-content style="flex: 1" class="align-self-start font-weight-bold">Date:</v-list-item-content>
								<v-list-item-content style="flex: 2" class="align-end" v-text="markerDialog.marker.data[markerDialog.index].date ? $options.filters.dateFormat(markerDialog.marker.data[markerDialog.index].date, 'MMM Do, YYYY') : 'Undefined'"></v-list-item-content>
							</v-list-item>
							<v-list-item v-if="markerDialog.marker.data[markerDialog.index].description">
								<v-list-item-content style="flex: 1" class="align-self-start font-weight-bold">Description:</v-list-item-content>
								<v-list-item-content style="flex: 2" class="align-end" v-text="markerDialog.marker.data[markerDialog.index].description || 'Undefined'"></v-list-item-content>
							</v-list-item>
							<v-list-item>
								<v-list-item-content style="flex: 1" class="align-self-start font-weight-bold">Container:</v-list-item-content>
								<v-list-item-content style="flex: 2" class="align-end" v-text="markerDialog.marker.data[markerDialog.index].container || 'Undefined'"></v-list-item-content>
							</v-list-item>
							<v-list-item>
								<v-list-item-content style="flex: 1" class="align-self-start font-weight-bold">Substance:</v-list-item-content>
								<v-list-item-content style="flex: 2" class="align-end" v-text="markerDialog.marker.data[markerDialog.index].substance || 'Undefined'"></v-list-item-content>
							</v-list-item>
							<v-list-item>
								<v-list-item-content style="flex: 1" class="align-self-start font-weight-bold">Actual:</v-list-item-content>
								<v-list-item-content style="flex: 2" class="align-end" v-text="markerDialog.marker.data[markerDialog.index].actual || 'Undefined'"></v-list-item-content>
							</v-list-item>
							<v-list-item>
								<v-list-item-content style="flex: 1" class="align-self-start font-weight-bold">Target Type:</v-list-item-content>
								<v-list-item-content style="flex: 2" class="align-end" v-text="markerDialog.marker.data[markerDialog.index].target_actual || 'Undefined'"></v-list-item-content>
							</v-list-item>
							<v-list-item>
								<v-list-item-content style="flex: 1" class="align-self-start font-weight-bold">Primary Responder:</v-list-item-content>
								<v-list-item-content style="flex: 2" class="align-end" v-text="markerDialog.marker.data[markerDialog.index].primary_responder || 'Undefined'"></v-list-item-content>
							</v-list-item>
							<v-list-item>
								<v-list-item-content style="flex: 1" class="align-self-start font-weight-bold">Source:</v-list-item-content>
								<v-list-item-content style="flex: 2" class="align-end">
									<a :href="markerDialog.marker.data[markerDialog.index].source" target="_blank" v-text="markerDialog.marker.data[markerDialog.index].source"></a>
								</v-list-item-content>
							</v-list-item>
						</v-list>
					</v-card>
				</template>

				<template #footer>
					<v-divider></v-divider>
					<v-card-actions class="d-flex justify-space-between">
						<v-btn @click="handleMarkerModalPrev" :disabled="!canMarkerModalGoPrev">
							<v-icon>mdi-arrow-left</v-icon>
						</v-btn>
						<span class="mx-4">Page {{markerDialog.index + 1}} of {{markerDialog.marker.data.length}}</span>
						<v-btn @click="handleMarkerModalNext" :disabled="!canMarkerModalGoNext">
							<v-icon>mdi-arrow-right</v-icon>
						</v-btn>
					</v-card-actions>
				</template>
			</Modal>
		</template>
	</v-sheet>
</template>

<script>
import Vue from 'vue';
import MapView from '@/components/MapView';
import Modal from '@/components/Modal';
import moment from 'moment';
import credentials from '../../.google-creds.json';

import { GoogleSpreadsheet } from 'google-spreadsheet';

export default Vue.extend({
	name: 'Home',

	components: { MapView, Modal },

	created() {
		this.load(true);
	},

	data: () => ({
		loading: false,
		center: { lat: 41.850033, lng: -96.6500523 },
		zoom: 4,
		markers: [],
		visibleMarkers: [],
		rows: [],
		years: [],
		ranges: [
			{ text: 'Last 30 days', value: [30, 'days'] },
			{ text: 'Last month', value: [1, 'month'] },
			{ text: 'Last 3 months', value: [3, 'months'] },
			{ text: 'Last 6 months', value: [6, 'months'] },
			{ text: 'Last year', value: [1, 'year'] },
			{ text: 'Last 5 years', value: [5, 'years'] },
		],
		containers: [],
		drugs: [],
		cbrns: [],
		substances: [],
		targetTypes: [],
		responders: [],
		filters: {
			year: null,
			container: null,
			drug: null,
			cbrn: null,
			substance: null,
			target_type: null,
			primary_responder: null,
		},
		mappers: [
			['container', 'containers'],
			['drug', 'drugs'],
			['cbrn', 'cbrns'],
			['substance', 'substances'],
			['target_type', 'targetTypes'],
			['primary_responder', 'responders'],
		],
		markerDialog: {
			visible: false,
			index: 0,
			marker: {
				position: { lat: 0, lng: 0 },
				data: [],
			},
		},
	}),

	computed: {
		canMarkerModalGoPrev() {
			return this.markerDialog.index > 0;
		},
		canMarkerModalGoNext() {
			return this.markerDialog.marker.data.length - 1 > this.markerDialog.index;
		},
		_news() {
			const sources = [];
			return this.visibleMarkers.map(marker => marker.data).flat().sort(function(a, b) {
				return moment(a.date).unix() - moment(b.date).unix();
			}).reverse().map(item => ({
				...item,
				formattedDate: moment(item.date).format('ll'),
			})).filter(item => {
				if (sources.indexOf(item.source) === -1) {
					sources.push(item.source);
					return true;
				} else {
					return false;
				}
			}).slice(0, 10);
		},
	},

	watch: {
		filters: {
			deep: true,
			handler(filters) {
				this.markers = this.getMarkers(this.rows, filters);
				if (this.$refs.map) {
					this.$nextTick(() => {
						this.$refs.map.handleMapMoved();
					});
				}
			},
		}
	},

	methods: {
		handleMarkerModalPrev() {
			if (this.canMarkerModalGoPrev) {
				this.markerDialog.index--;
			}
		},
		handleMarkerModalNext() {
			if (this.canMarkerModalGoNext) {
				this.markerDialog.index++;
			}
		},
		handleMapMoved(markers) {
			this.visibleMarkers = markers;
		},

		handleMarkerClick(marker) {
			Object.assign(this.markerDialog, {
				visible: true,
				index: 0,
				marker,
			});
		},

		async load(updateYears = false) {
			this.loading = true;

			// https://theoephraim.github.io/node-google-spreadsheet/#/getting-started/authentication
			const doc = new GoogleSpreadsheet('1R5yktnOmP383YWdWPQQaCWDNeOXG86BJxv5phDtWlDE');
			await doc.useServiceAccountAuth(credentials);
			await doc.loadInfo();

			const sheet = doc.sheetsByTitle['Website'];
			const gRows = await sheet.getRows();
			const rows = [];
			const years = [];
			gRows.forEach(row => {
				const data = {};
				row._sheet.headerValues.forEach((header, index) => {
					data[header.toLowerCase().replaceAll(' ', '_')] = row._rawData[index];
				});
				Object.keys(data).forEach(key => {
					switch(key) {
						case 'date1':
							data['date'] = moment(data[key]).toDate();
							break;
						case 'drug':
						case 'cbrn':
						case 'viable':
							data[key] = data[key] === 'Yes';
							break;
						case 'injured':
						case 'death':
						case 'success':
							data[key] = parseInt(data[key]);
							break;
						case 'latitude':
						case 'longitude':
							data[key] = parseFloat(data[key]);
							break;
						default:
							data[key] = (data[key] || '').trim();
							break;
					}
				});

				if (!data.latitude || !data.longitude || !data.date) {
					return;
				}

				if (!updateYears && this.filters.year !== null && parseInt(moment(data['date']).format('YYYY')) < this.filters.year) {
					return;
				}

				const rowYear = parseInt(moment(data.date).format('YYYY'));
				if (years.indexOf(rowYear) === -1) {
					years.push(rowYear);
				}

				rows.push(data);
			});

			rows.sort(function(a, b) {
				return parseFloat(a.timestamp) - parseFloat(b.timestamp);
			});

			if (updateYears) {
				this.filters.year = years.length === 1 ? years[0] : null;
				this.years.splice(0, this.years.length);
				this.years.push(...years);
			}

			this.populateFilters(rows);
			this.markers = this.getMarkers(rows);
			this.rows = rows;

			this.$root.getSubstanceIcon = substance => {
				// const index = this.substances.findIndex(item => item.toLowerCase().trim() === substance.toLowerCase().trim()) || 0;
				return this.$options.filters.substanceIcon(substance);
			}

			this.loading = false;
		},

		getMarkers(rows, filters = null) {
			const markers = [];
			rows.forEach(row => {

				// Clear out filtered items
				if (filters !== null) {
					for (let i = 0; i < this.mappers.length; i++) {
						const [ key, mapper ] = this.mappers[i];
						if (this.filters[key] !== null && row[key] !== this.filters[key]) {
							return;
						}
					}

					if (filters.range && moment(row.date).unix() < moment().subtract(filters.range.value[0], filters.range.value[1]).unix()) {
						return;
					}
				}

				let marker = markers.find(marker => marker.position.lat === row.latitude && marker.position.lng === row.longitude);
				if (!marker) {
					markers.push({
						data: [
							row
						],
						position: {
							lat: row.latitude,
							lng: row.longitude
						}
					});
				} else {
					marker.data.push(row);
				}
			})
			return markers;
		},

		populateFilters(rows) {
			rows.forEach(row => {
				this.mappers.forEach(([key, mapper]) => {
					if (this[mapper].indexOf(row[key]) === -1) {
						this[mapper].push(row[key]);
					}
				});
			});
			this.mappers.forEach(([key, mapper]) => {
				this[mapper].sort();
			});
		},
	},
});
</script>
