<template>
	<gmap-map
		ref="map"
		v-bind="$attrs"
		@dragend="handleMapMoved"
		@zoom_changed="handleMapMoved"
		@idle="handleMapMoved"
	>
		<gmap-cluster
			v-if="map"
			:animation="5"
			:zoomOnClick="true"
			:styles="clusterStyle"
		>
			<gmap-marker
				v-for="(marker, markerIdx) in markers"
				:key="markerIdx"
				:position="marker.position"
				:clickable="true"
				:icon="getMarkerIcon(marker)"
				@click="handleMarkerClick(markers[markerIdx])"
			/>
		</gmap-cluster>
	</gmap-map>
</template>

<script>
import GmapCluster from 'vue2-google-maps/dist/components/cluster'
import clusterDefault from '@/assets/markers/cluster.png'

// https://www.npmjs.com/package/vue2-google-maps
// icons: https://sites.google.com/site/gmapsdevelopment/

export default {
	name: "MapView",

	components: { GmapCluster },

	props: {
		markers: {
			type: Array,
			default: () => ([]),
		},
	},

	data: () => ({
		map: null,
		clusterStyle: [{
			url: clusterDefault,
			textColor: 'black',
			width: 32,
			height: 32,
			textSize: 12,
		}],
	}),

	mounted () {
		this.$refs.map.$mapPromise.then((map) => {
			this.map = map;
		});
	},

	methods: {
		handleMarkerClick(marker) {
			this.$emit('markerClick', marker);
		},
		handleMapMoved() {
			const markers = [];
			const bounds = this.map.getBounds();
			this.markers.forEach(marker => {
				if (bounds.contains(marker.position)) {
					markers.push(marker);
				}
			})
			this.$emit('bounds', markers);
		},
		getMarkerIcon(marker) {
			const url = this.$root.getSubstanceIcon(marker.data[0].substance.toLowerCase());
			return {
				url,
				scaledSize: new google.maps.Size(32, 32),
			};
		}
	},
}
</script>
